import * as i0 from '@angular/core';
import { EventEmitter, Directive, ViewChildren, forwardRef, Input, Output, Component, ChangeDetectionStrategy, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/core';
import { TuiOptionComponent, TuiTextfieldSizeDirective, tuiAsDataListAccessor, TUI_TEXTFIELD_WATCHED_CONTROLLER, TEXTFIELD_CONTROLLER_PROVIDER, TuiDataListModule, TuiLoaderModule, TuiDataListDirective } from '@taiga-ui/core';
import { TUI_ITEMS_HANDLERS } from '@taiga-ui/kit/tokens';
import * as i3 from '@taiga-ui/cdk';
import { EMPTY_QUERY, tuiIsNativeFocused, tuiIsPresent, TuiElementModule } from '@taiga-ui/cdk';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
const _c0 = ["labels", ""];
function TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_button_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r4 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r4, " ");
  }
}
function TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 7, 1);
    i0.ɵɵlistener("click", function TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_button_1_Template_button_click_0_listener() {
      const item_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.itemClick.emit(item_r2));
    });
    i0.ɵɵtemplate(2, TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_button_1_ng_container_2_Template, 2, 1, "ng-container", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const elementRef_r5 = i0.ɵɵreference(1);
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("disabled", ctx_r2.disabledItemHandler(item_r2))("value", item_r2);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.itemContent)("polymorpheusOutletContext", ctx_r2.getContext(item_r2, elementRef_r5));
  }
}
function TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-opt-group", 5);
    i0.ɵɵtemplate(1, TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_button_1_Template, 3, 4, "button", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const group_r6 = ctx.$implicit;
    const index_r7 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("label", ctx_r2.labels[index_r7]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", group_r6);
  }
}
function TuiDataListGroupWrapperComponent_tui_data_list_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-data-list", 3);
    i0.ɵɵtemplate(1, TuiDataListGroupWrapperComponent_tui_data_list_0_tui_opt_group_1_Template, 2, 2, "tui-opt-group", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("emptyContent", ctx_r2.emptyContent)("size", ctx_r2.size);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.items);
  }
}
function TuiDataListGroupWrapperComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-loader", 9);
  }
}
function TuiDataListWrapperComponent_tui_data_list_0_button_1_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r4 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r4, " ");
  }
}
function TuiDataListWrapperComponent_tui_data_list_0_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 5, 1);
    i0.ɵɵlistener("click", function TuiDataListWrapperComponent_tui_data_list_0_button_1_Template_button_click_0_listener() {
      const item_r2 = i0.ɵɵrestoreView(_r1).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.itemClick.emit(item_r2));
    });
    i0.ɵɵelementStart(2, "span", 6);
    i0.ɵɵtemplate(3, TuiDataListWrapperComponent_tui_data_list_0_button_1_ng_container_3_Template, 2, 1, "ng-container", 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r2 = ctx.$implicit;
    const elementRef_r5 = i0.ɵɵreference(1);
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r2.disabledItemHandler(item_r2))("value", item_r2);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r2.itemContent)("polymorpheusOutletContext", ctx_r2.getContext(item_r2, elementRef_r5));
  }
}
function TuiDataListWrapperComponent_tui_data_list_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "tui-data-list", 3);
    i0.ɵɵtemplate(1, TuiDataListWrapperComponent_tui_data_list_0_button_1_Template, 4, 4, "button", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("emptyContent", ctx_r2.emptyContent)("size", ctx_r2.size);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r2.items);
  }
}
function TuiDataListWrapperComponent_ng_template_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-loader", 8);
  }
}
const _c1 = "[_nghost-%COMP%]{display:block}.t-content[_ngcontent-%COMP%]{flex:1}.t-loader[_ngcontent-%COMP%]{margin:.75rem 0}";
class AbstractTuiDataListWrapper {
  constructor(itemsHandlers, defaultSize) {
    this.itemsHandlers = itemsHandlers;
    this.defaultSize = defaultSize;
    this.optionsQuery = EMPTY_QUERY;
    this.disabledItemHandler = this.itemsHandlers.disabledItemHandler;
    this.size = this.defaultSize;
    this.itemClick = new EventEmitter();
    this.itemContent = ({
      $implicit
    }) => this.itemsHandlers.stringify($implicit);
  }
  getContext($implicit, {
    nativeElement
  }) {
    return {
      $implicit,
      active: tuiIsNativeFocused(nativeElement)
    };
  }
  getOptions(includeDisabled = false) {
    return this.optionsQuery.filter(({
      disabled
    }) => includeDisabled || !disabled).map(({
      value
    }) => value).filter(tuiIsPresent);
  }
}
AbstractTuiDataListWrapper.ɵfac = function AbstractTuiDataListWrapper_Factory(t) {
  i0.ɵɵinvalidFactory();
};
AbstractTuiDataListWrapper.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiDataListWrapper,
  viewQuery: function AbstractTuiDataListWrapper_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiOptionComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.optionsQuery = _t);
    }
  },
  inputs: {
    disabledItemHandler: "disabledItemHandler",
    emptyContent: "emptyContent",
    size: "size",
    itemContent: "itemContent"
  },
  outputs: {
    itemClick: "itemClick"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiDataListWrapper, [{
    type: Directive
  }], function () {
    return [{
      type: undefined
    }, {
      type: undefined
    }];
  }, {
    optionsQuery: [{
      type: ViewChildren,
      args: [forwardRef(() => TuiOptionComponent)]
    }],
    disabledItemHandler: [{
      type: Input
    }],
    emptyContent: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    itemClick: [{
      type: Output
    }],
    itemContent: [{
      type: Input
    }]
  });
})();
class TuiDataListGroupWrapperComponent extends AbstractTuiDataListWrapper {
  constructor(itemsHandlers, controller) {
    super(itemsHandlers, (controller === null || controller === void 0 ? void 0 : controller.size) || 'm');
    this.items = [];
    this.labels = [];
  }
}
TuiDataListGroupWrapperComponent.ɵfac = function TuiDataListGroupWrapperComponent_Factory(t) {
  return new (t || TuiDataListGroupWrapperComponent)(i0.ɵɵdirectiveInject(TUI_ITEMS_HANDLERS), i0.ɵɵdirectiveInject(TuiTextfieldSizeDirective, 8));
};
TuiDataListGroupWrapperComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiDataListGroupWrapperComponent,
  selectors: [["tui-data-list-wrapper", "labels", ""]],
  inputs: {
    items: "items",
    labels: "labels"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDataListAccessor(TuiDataListGroupWrapperComponent)]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c0,
  decls: 3,
  vars: 2,
  consts: [["loading", ""], ["elementRef", "elementRef"], [3, "emptyContent", "size", 4, "ngIf", "ngIfElse"], [3, "emptyContent", "size"], [3, "label", 4, "ngFor", "ngForOf"], [3, "label"], ["automation-id", "tui-data-list-wrapper__option", "tuiElement", "", "tuiOption", "", 3, "disabled", "value", "click", 4, "ngFor", "ngForOf"], ["automation-id", "tui-data-list-wrapper__option", "tuiElement", "", "tuiOption", "", 3, "click", "disabled", "value"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-loader"]],
  template: function TuiDataListGroupWrapperComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiDataListGroupWrapperComponent_tui_data_list_0_Template, 2, 3, "tui-data-list", 2)(1, TuiDataListGroupWrapperComponent_ng_template_1_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const loading_r8 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.items)("ngIfElse", loading_r8);
    }
  },
  dependencies: [i1.TuiDataListComponent, i1.TuiOptionComponent, i1.TuiLoaderComponent, i2.NgIf, i2.NgForOf, i1.TuiOptGroupDirective, i3.TuiElementDirective, i4.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{display:block}.t-content[_ngcontent-%COMP%]{flex:1}.t-loader[_ngcontent-%COMP%]{margin:.75rem 0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDataListGroupWrapperComponent, [{
    type: Component,
    args: [{
      selector: 'tui-data-list-wrapper[labels]',
      templateUrl: './data-list-group-wrapper.template.html',
      styleUrls: ['./data-list-wrapper.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataListAccessor(TuiDataListGroupWrapperComponent)]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ITEMS_HANDLERS]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiTextfieldSizeDirective]
      }]
    }];
  }, {
    items: [{
      type: Input
    }],
    labels: [{
      type: Input
    }]
  });
})();
class TuiDataListWrapperComponent extends AbstractTuiDataListWrapper {
  constructor(itemsHandlers, controller) {
    super(itemsHandlers, (controller === null || controller === void 0 ? void 0 : controller.size) || 'm');
    this.items = [];
  }
}
TuiDataListWrapperComponent.ɵfac = function TuiDataListWrapperComponent_Factory(t) {
  return new (t || TuiDataListWrapperComponent)(i0.ɵɵdirectiveInject(TUI_ITEMS_HANDLERS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_WATCHED_CONTROLLER, 8));
};
TuiDataListWrapperComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiDataListWrapperComponent,
  selectors: [["tui-data-list-wrapper", 3, "labels", ""]],
  inputs: {
    items: "items"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDataListAccessor(TuiDataListWrapperComponent), TEXTFIELD_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  decls: 3,
  vars: 2,
  consts: [["loading", ""], ["elementRef", "elementRef"], [3, "emptyContent", "size", 4, "ngIf", "ngIfElse"], [3, "emptyContent", "size"], ["automation-id", "tui-data-list-wrapper__option", "tuiElement", "", "tuiOption", "", 3, "disabled", "value", "click", 4, "ngFor", "ngForOf"], ["automation-id", "tui-data-list-wrapper__option", "tuiElement", "", "tuiOption", "", 3, "click", "disabled", "value"], [1, "t-content"], [4, "polymorpheusOutlet", "polymorpheusOutletContext"], ["automation-id", "tui-data-list-wrapper__loader", 1, "t-loader"]],
  template: function TuiDataListWrapperComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiDataListWrapperComponent_tui_data_list_0_Template, 2, 3, "tui-data-list", 2)(1, TuiDataListWrapperComponent_ng_template_1_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const loading_r6 = i0.ɵɵreference(2);
      i0.ɵɵproperty("ngIf", ctx.items)("ngIfElse", loading_r6);
    }
  },
  dependencies: [i1.TuiDataListComponent, i1.TuiOptionComponent, i1.TuiLoaderComponent, i2.NgIf, i2.NgForOf, i3.TuiElementDirective, i4.PolymorpheusOutletDirective],
  styles: [_c1],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDataListWrapperComponent, [{
    type: Component,
    args: [{
      selector: 'tui-data-list-wrapper:not([labels])',
      templateUrl: './data-list-wrapper.template.html',
      styleUrls: ['./data-list-wrapper.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataListAccessor(TuiDataListWrapperComponent), TEXTFIELD_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ITEMS_HANDLERS]
      }]
    }, {
      type: i1.TuiTextfieldController,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_TEXTFIELD_WATCHED_CONTROLLER]
      }]
    }];
  }, {
    items: [{
      type: Input
    }]
  });
})();
class TuiDataListWrapperModule {}
TuiDataListWrapperModule.ɵfac = function TuiDataListWrapperModule_Factory(t) {
  return new (t || TuiDataListWrapperModule)();
};
TuiDataListWrapperModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiDataListWrapperModule
});
TuiDataListWrapperModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiElementModule, TuiDataListModule, TuiLoaderModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDataListWrapperModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiElementModule, TuiDataListModule, TuiLoaderModule],
      declarations: [TuiDataListWrapperComponent, TuiDataListGroupWrapperComponent],
      exports: [TuiDataListWrapperComponent, TuiDataListGroupWrapperComponent, TuiDataListDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiDataListWrapper, TuiDataListGroupWrapperComponent, TuiDataListWrapperComponent, TuiDataListWrapperModule };
